import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { StorageService } from '@app/services/storage.service';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  try {
    const auth$: StorageService = inject(StorageService);
    if (!auth$.isLogged()) {
      return next(req);
    } else {
      const request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${auth$.session().access}`),
      });
      return next(request);
    }
  } catch {
    return next(req);
  }
};
