import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/env';
import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://ec1ca1723fc565a8b5978989d964547e@o4508374103490560.ingest.us.sentry.io/4508374329393152',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    // tracePropagationTargets: ['localhost', /^https:\/\/bitus\.cl\/.*/],
    debug: environment.production ? false : true,
  });
  window.console.log = function () {};
  window.console.error = function () {};
  window.console.warn = function () {};
  window.console.info = function () {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
  })
  .catch((err) => console.error(err));
